<template>
  <Toast />
  <div style="display: flex; flex-direction: column; width: 100%">
    <button @click="returnToBiaHome" class="linkButton" style="
        display: flex;
        gap: 8px;
        margin-bottom: 64px;
        justify-content: start;
        width: 260px;
        cursor: pointer;
      ">
      <i class="pi pi-chevron-left" style="color: var(--color_brand_medium)"></i>
      <div class="body2" style="color: var(--color_brand_medium)">
        {{ this.$langGetValue('returnToCampaignList') }}
      </div>
    </button>
    <div v-if="campaignQueryLocal != null" style="display: flex; justify-content: space-between; margin-bottom: 64px">
      <div style="
          display: flex;
          flex-direction: column;
          justify-content: start;
          gap: 8px;
          width: 20%;
        ">
        <div style="display: flex; flex-direction: column; justify-content: start">
          <div class="body3">{{ this.$langGetValue('biaCreation') }}</div>
          <div class="body2" style="font-weight: 700">
            {{ returnDate(campaignQueryLocal.data.dateOfStart) }}
          </div>
        </div>
        <div v-if="roleManager" style="display: flex; flex-direction: column; justify-content: start">
          <div class="body3">{{ this.$langGetValue('biaExpectedConclusion') }}</div>
          <div class="body2" style="font-weight: 700">
            {{ returnDate(campaignQueryLocal.data.estimatedDateOfConclusion) }}
          </div>
        </div>
      </div>
      <div style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          width: 60%;
        ">
        <div class="h2">
          {{ campaignQueryLocal.data.name }}
        </div>
      </div>
      <div style="
          display: flex;
          flex-direction: column;
          justify-content: end;
          align-items: end;
          gap: 8px;
          width: 20%;
        ">
        <!-- <div v-if="roleAdmin || roleManager" style="display: flex" class="body3">
          Progresso total
        </div>
        <ProgressBar v-if="roleAdmin || roleManager" :percentual="50" /> -->
      </div>
    </div>
    <div style="
        display: flex;
        justify-content: start;
        flex-direction: column;
        margin-bottom: 24px;
      ">
      <div style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 24px;
          height: 24px;
          margin-bottom: 16px;
        ">
        <div class="h3">{{ this.$langGetValue('areas') }}</div>
        <div style="
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
            margin: 24px 0px 24px 0px;
          ">
          <!-- <div class="body2" style="display: flex; gap: 8px; align-items: center;">
                Pendente validação
                <Tag :value="this.counter.toValidate" rounded style="background-color: var(--color_brand_medium); min-width: 22px;"></Tag>
            </div> -->
          <!-- <div class="body2" style="
              display: flex;
              gap: 8px;
              align-items: center;
              background-color: var(--color_gray_xlight);
              border-radius: 40px;
              padding: 0px 0px 0px 8px;
              font-weight: 700;
              color: var(--color_gray_dark);
            ">
            Validado
            <Tag :value="this.counter.validated" rounded style="
                background-color: var(--color_brand_xlight);
                min-width: 26px;
                color: var(--color_brand_xdark);
                font-weight: 900;
              "></Tag>
          </div> -->
          <div class="body2" style="
              display: flex;
              gap: 8px;
              align-items: center;
              background-color: var(--color_gray_xlight);
              border-radius: 40px;
              padding: 0px 0px 0px 8px;
              font-weight: 700;
              color: var(--color_gray_dark);
            ">
            {{ this.$langGetValue('biaStatus1') }}
            <Tag :value="this.counter.toFill" rounded style="
                background-color: var(--color_brand_light);
                min-width: 26px;
                color: var(--color_brand_xdark);
              "></Tag>
          </div>
          <div class="body2" style="
              display: flex;
              gap: 8px;
              align-items: center;
              background-color: var(--color_gray_xlight);
              border-radius: 40px;
              padding: 0px 0px 0px 8px;
              font-weight: 700;
              color: var(--color_gray_dark);
            ">
            {{ this.$langGetValue('biaStatus2') }}
            <Tag :value="this.counter.pendingBpmApproval" rounded style="
                background-color: var(--color_brand_medium);
                min-width: 26px;
              "></Tag>
          </div>
          <div class="body2" style="
              display: flex;
              gap: 8px;
              align-items: center;
              background-color: var(--color_gray_xlight);
              border-radius: 40px;
              padding: 0px 0px 0px 8px;
              font-weight: 700;
              color: var(--color_gray_dark);
            ">
            <div v-if="checkTenant() == 'btg'">
              {{ this.$langGetValue('biaStatus3') }}
            </div>
            <div v-else>
              {{ this.$langGetValue('biaStatus3Pan') }}
            </div>
            <Tag :value="this.counter.pendingRoApproval" rounded
              style="background-color: var(--color_brand_dark); min-width: 26px"></Tag>
          </div>
          <div class="body2" style="
              display: flex;
              gap: 8px;
              align-items: center;
              background-color: var(--color_gray_xlight);
              border-radius: 40px;
              padding: 0px 0px 0px 8px;
              font-weight: 700;
              color: var(--color_gray_dark);
            ">
            {{ this.$langGetValue('biaStatus4') }}
            <Tag :value="this.counter.closed" rounded style="
                background-color: #479356;
                min-width: 26px;
              "></Tag>
          </div>
        </div>
      </div>
      <div style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 24px 0px 16px 0px;
          width: 100%
        ">
        <Button v-if="this.filterOpened == false && this.filterActive == false" @click="setFilterState"
          class="secondaryButton" :label="this.$langGetValue('filter')" icon="pi pi-filter"
          style="width: fit-content" />

        <div v-if="this.filterOpened == true" style="
            display: flex;
            gap: 16px;
            align-items: center;
            width: 80%;
            justify-content: start;
            gap: 16px;
          ">
          <Button v-if="this.filterOpened == true" @click="removeFilter" class="secondaryButton" icon="pi pi-times"
            style="height: 48px; width: 48px" />
          <Dropdown v-model="filterOptionSelected" :options="optionsListFilter" optionLabel="option"
            placeholder="O que deseja filtrar?" style="width: 20%; height: 48px;" />
          <MultiSelect v-show="this.filterOptionSelected.id == 0" v-model="areasFilterSelected" display="chip"
            :options="areasListFilter" optionLabel="label" :placeholder="this.$langGetValue('biaFilterAreas')"
            style="width: 30%; height: 48px;" filter />
          <MultiSelect v-show="this.filterOptionSelected.id == 1" v-model="statusFilterSelected" display="chip"
            :options="statusListFilter" optionLabel="option" :placeholder="this.$langGetValue('biaSelectStatus')"
            style="width: 30%; height: 48px;" filter />
          <MultiSelect v-show="this.filterOptionSelected.id == 2" v-model="rexFilterSelected" display="chip"
            :options="rexListFilter" optionLabel="name" :placeholder="this.$langGetValue('biaSelectRex')"
            style="width: 30%; height: 48px;" filter />
          <MultiSelect v-show="this.filterOptionSelected.id == 3" v-model="bpmFilterSelected" display="chip"
            :options="bpmListFilter" optionLabel="name" :placeholder="this.$langGetValue('biaSelectBpm')"
            style="width: 30%; height: 48px;" filter />
          <Button @click="filterAreas" class="primaryButton" icon="pi pi-search" style="height: 48px; width: 48px" />
        </div>
        <div v-if="this.filterOpened == false && this.filterActive == true" style="
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: start;
          ">
          <Chip icon="pi pi-trash" style="
              background-color: transparent;
              color: #eb3d47;
              padding: 0px;
              font-weight: 600;
              cursor: pointer;
            " label="LIMPAR" @click="removeFilter" />
          <Divider layout="vertical" style="height: 100%" />
          <div
            v-if="this.areasFilterSelected.length > 0 && this.filterOptionSelected.option == this.$langGetValue('area')"
            style="width: 80%; display: flex; flex-direction: column; gap: 8px">
            <div class="body3">{{ this.$langGetValue('biaFilteredAreas') }}</div>
            <div class="flex-wrap" style="display: flex; gap: 8px; width: 100%">
              <Chip class="secondaryChip" v-for="(area, index) in areasFilterSelected" :key="index" :label="area.label">
              </Chip>
            </div>
          </div>
          <div v-if="this.statusFilterSelected.length > 0 && this.filterOptionSelected.option == 'Status'"
            style=" display: flex; flex-direction: column; gap: 8px">
            <div class="body3">{{ this.$langGetValue('biaFilteredStatus') }}</div>
            <div class="flex-wrap" style="display: flex; gap: 8px; width: 100%">
              <Chip class="secondaryChip" v-for="(status, index) in statusFilterSelected" :key="index"
                :label="status.option"></Chip>
            </div>
          </div>
          <div v-if="this.rexFilterSelected.length > 0 && this.filterOptionSelected.option == 'REX'"
            style=" display: flex; flex-direction: column; gap: 8px">
            <div class="body3">{{ this.$langGetValue('biaFilteredRex') }}</div>
            <div class="flex-wrap" style="display: flex; gap: 8px; width: 100%">
              <Chip class="secondaryChip" v-for="(rex, index) in rexFilterSelected" :key="index" :label="rex.name">
              </Chip>
            </div>
          </div>
          <div v-if="this.bpmFilterSelected.length > 0 && this.filterOptionSelected.option == 'BPM'"
            style=" display: flex; flex-direction: column; gap: 8px">
            <div class="body3">{{ this.$langGetValue('biaFilteredBpm') }}</div>
            <div class="flex-wrap" style="display: flex; gap: 8px; width: 100%">
              <Chip class="secondaryChip" v-for="(bpm, index) in bpmFilterSelected" :key="index" :label="bpm.name">
              </Chip>
            </div>
          </div>
        </div>

        <div class="body2" style="display: flex; gap: 8px; align-items: center; width: fit-content;">
          {{ this.$langGetValue('biaStatusView') }}
          <InputSwitch v-model="stepper" />
        </div>
      </div>
    </div>
    <div v-if="campaignQueryLocal != null && campaignQueryLocal.data?.data != null" style="width: 100%">
      <DataTable class="customDataTableCampaign" v-model:expandedRows="expandedRows"
        :value="campaignQueryLocal.data.data">
        <Column expander style="width: 3rem; padding-right: 0"></Column>
        <Column style="padding-left: 0" field="header">
          <template #body="slotProps">
            <div style="
                display: flex;
                flex-direction: row;
                padding: 0px 0px 0px 16px;
                justify-content: space-between;
                width: 100%;
              ">
              <div style="display: flex; flex-direction: column; gap: 8px">
                <div class="body1" style="font-weight: 700">
                  {{ getAreaNameById(slotProps.data.area) }}
                </div>
                <div style="
                    display: flex;
                    flex-direction: row;
                    gap: 16px;
                    align-items: center;
                  ">
                  <!-- <div class="body3" style="color: var(--color_gray_medium)">
                    <b>Última atualização:</b> 12/04/2023
                  </div>
                  <div class="body3" style="color: var(--color_gray_medium); font-size: 8px">
                    ●
                  </div>
                  <div class="body3" style="color: var(--color_gray_medium)">
                    Aqui vai um texto
                  </div> -->
                </div>
              </div>
            </div>
          </template>
        </Column>
        <template #expansion="slotProps">
          <div style="
              display: flex;
              flex-direction: column;
              justify-content: end;
              align-items: center;
              width: 100%;
            ">
            <BiaFormsResults v-for="(process, index) in slotProps.data.process" :id="process.id" :key="index"
              :biaCode="process.name" :description="process.data.name" :status="process.data.status"
              :bpmName="process.data.bpm" :rexName="process.data.rex" :stepper="stepper" />
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import "../Global.css";
import BiaFormsResults from "../components/BiaFormsResults.vue";
// import ProgressBar from "./ProgressBar.vue";
import FieldService from "../service/FieldService.js";
import Util from "../service/Util";
import UserService from "../service/UserService";

export default {
  components: {
    BiaFormsResults,
    // ProgressBar,
  },
  props: {
    campaignQuery: Array,
  },
  watch: {

  },
  data() {
    return {
      stepper: false,
      expandedRows: [],
      campaignQueryLocal: null,

      //Filtro
      areasListFilter: null,
      bpmListFilter: [],
      rexFilterSelected: [],
      bpmFilterSelected: [],
      rexListFilter: [],
      allAreasList: [],
      areaHierarchy: FieldService.getField('bia', 'associatedArea'),
      areasFilterSelected: [],
      filterActive: false,
      filterOpened: false,
      statusFilterSelected: [],
      statusListFilter: [
        { option: this.$langGetValue('biaStatus1'), label: "toFill" },
        { option: this.$langGetValue('biaStatus5'), label: "reFill" },
        { option: this.$langGetValue('biaStatus2'), label: "pendingBpmApproval" },
        { option: this.checkTenant() == 'btg' ? this.$langGetValue('biaStatus3') : this.$langGetValue('biaStatus3Pan'), label: "pendingRoApproval" },
        { option: this.$langGetValue('biaStatus4'), label: "closed" }
      ],
      filterOptionSelected: null,
      optionsListFilter: [
        { option: this.$langGetValue('area'), id: "0" },
        { option: "Status", id: "1" },
        { option: "REX", id: "2" },
        { option: "BPM", id: "3" },
      ],
      //
      roleManager: false,
      user: UserService.user,
      counter: {
        toValidate: 0,
        validated: 0,
        toFill: 0,
        pendingBpmApproval: 0,
        pendingRoApproval: 0,
        closed: 0,
      },
    };
  },
  async created() {
    await this.populateHierarchyArray();

    this.roleManager = UserService.checkUserRoleAny(['*.storm.bia.*.manage']);

    this.mountPage();
  },
  methods: {
    checkTenant() {
      return UserService.company
    },
    mountPage() {
      this.filterOptionSelected = this.optionsListFilter[0];

      var tempQuery = JSON.parse(JSON.stringify(this.campaignQuery));

      if (!this.roleManager) {
        var tempProcess = null;

        tempQuery.data.data.forEach((area) => {
          tempProcess = area.process.filter(
            (process) =>
              process.data.bpm.includes(this.user) ||
              process.data.rex.includes(this.user)
          );
          area.process = tempProcess;
        });

        var filteredTempQuery = null;
        filteredTempQuery = tempQuery.data.data.filter((area) => {
          return area.process.length > 0;
        });

        tempQuery.data.data = filteredTempQuery;
      }

      var tempAreas = []
      var tempRex = []
      var tempBpm = []
      tempQuery.data.data.forEach((processes) => {
        tempAreas.push(processes.area)
        processes.process.forEach((process) => {
          tempRex.push(process.data.rex)
          tempBpm.push(process.data.bpm)
        })
      })

      this.areasListFilter = this.allAreasList.filter(area => tempAreas.includes(area.id))

      var tempRexUnique = [...new Set(tempRex)]
      var tempBpmUnique = [...new Set(tempBpm)]

      this.rexListFilter = []
      tempRexUnique.forEach((rex) => {
        this.rexListFilter.push({ name: rex })
      })

      this.bpmListFilter = []
      tempBpmUnique.forEach((bpm) => {
        this.bpmListFilter.push({ name: bpm })
      })

      this.campaignQueryLocal = tempQuery;
      this.expandedRows = this.campaignQueryLocal.data.data
      this.countStatus();
    },
    countStatus() {
      this.counter = {
        toValidate: 0,
        validated: 0,
        toFill: 0,
        pendingBpmApproval: 0,
        pendingRoApproval: 0,
        closed: 0,
      }

      this.campaignQueryLocal.data.data.forEach((area) => {
        area.process.forEach((process) => {

          switch (process.data.status) {
            case "toValidate":
              this.counter.toValidate++;
              break;
            case "validated":
              this.counter.validated++;
              break;
            case "toFill":
              this.counter.toFill++;
              break;
            case "reFill":
              this.counter.toFill++;
              break;
            case "pendingBpmApproval":
              this.counter.pendingBpmApproval++;
              break;
            case "pendingRoApproval":
              this.counter.pendingRoApproval++;
              break;
            case "closed":
              this.counter.closed++;
              break;
          }
        });
      });
    },
    checkRole(array) {
      return UserService.checkUserRoleAny(array);
    },
    returnDate(date) {
      return Util.formatDate(date, false);
    },
    getAreaNameById(id) {
      var areaName = null;

      areaName = this.allAreasList.filter((area) => area.id == id);

      return areaName[0]?.label;
    },
    returnToBiaHome() {
      this.$router.push({ name: "bia" });
    },
    setFilterState() {
      this.filterOpened = !this.filterOpened;
    },
    async filterAreas() {
      var tempQuery = JSON.parse(JSON.stringify(this.campaignQuery));

      if (this.filterOptionSelected.option == this.$langGetValue('area')) {
        if (this.areasFilterSelected.length == 0) {
          this.$toast.add({
            severity: "error",
            summary: this.$langGetValue('selectAtLeastOneArea'),
            life: 5000,
          });
          return
        }

        const selectedIds = this.areasFilterSelected.map(area => area.id);

        var tempFilterAreas = []

        tempQuery.data.data.forEach((area) => {
          if (selectedIds.includes(area.area)) {
            tempFilterAreas.push(area)
          }
        })

        this.campaignQueryLocal.data.data = null
        await this.$nextTick();
        this.campaignQueryLocal.data.data = tempFilterAreas

        setTimeout(() => {
          this.expandedRows = tempFilterAreas
        }, 200);

        this.filterOpened = false;
        this.filterActive = true;
      }
      if (this.filterOptionSelected.option == "Status") {
        if (this.statusFilterSelected.length == 0) {
          this.$toast.add({
            severity: "error",
            summary: this.$langGetValue('selectAtLeastOneStatus'),
            life: 5000,
          });
          return
        }

        const selectedStatus = this.statusFilterSelected.map(status => status.label);

        var tempFilterStatus = []

        tempQuery.data.data.forEach((area) => {
          var tempProcessStatus = []

          area.process.forEach((process) => {
            if (selectedStatus.includes(process.data.status)) {
              tempProcessStatus.push(process)
            }
          })

          if (tempProcessStatus.length > 0) {
            tempFilterStatus.push(
              {
                area: area.area,
                process: tempProcessStatus
              })
          }
        })

        this.campaignQueryLocal.data.data = null
        await this.$nextTick();
        this.campaignQueryLocal.data.data = tempFilterStatus

        setTimeout(() => {
          this.expandedRows = tempFilterStatus
        }, 200);

        this.filterOpened = false;
        this.filterActive = true;
      }
      if (this.filterOptionSelected.option == "REX") {
        if (this.rexFilterSelected.length == 0) {
          this.$toast.add({
            severity: "error",
            summary: this.$langGetValue('selectAtLeastOneREX'),
            life: 5000,
          });
          return
        }

        const selectedRex = this.rexFilterSelected.map(rex => rex.name);

        var tempFilterRex = []

        tempQuery.data.data.forEach((area) => {
          var tempProcessRex = []

          area.process.forEach((process) => {
            if (selectedRex.includes(process.data.rex)) {
              tempProcessRex.push(process)
            }
          })

          if (tempProcessRex.length > 0) {
            tempFilterRex.push(
              {
                area: area.area,
                process: tempProcessRex
              })
          }
        })

        this.campaignQueryLocal.data.data = null
        await this.$nextTick();
        this.campaignQueryLocal.data.data = tempFilterRex

        setTimeout(() => {
          this.expandedRows = tempFilterRex
        }, 200);

        this.filterOpened = false;
        this.filterActive = true;

      }
      if (this.filterOptionSelected.option == "BPM") {
        if (this.bpmFilterSelected.length == 0) {
          this.$toast.add({
            severity: "error",
            summary: this.$langGetValue('selectAtLeastOneBPM'),
            life: 5000,
          });
          return
        }

        const selectedBpm = this.bpmFilterSelected.map(bpm => bpm.name);

        var tempFilterBpm = []

        tempQuery.data.data.forEach((area) => {
          var tempProcessBpm = []

          area.process.forEach((process) => {
            if (selectedBpm.includes(process.data.bpm)) {
              tempProcessBpm.push(process)
            }
          })

          if (tempProcessBpm.length > 0) {
            tempFilterBpm.push(
              {
                area: area.area,
                process: tempProcessBpm
              })
          }
        })

        this.campaignQueryLocal.data.data = null
        await this.$nextTick();
        this.campaignQueryLocal.data.data = tempFilterBpm

        setTimeout(() => {
          this.expandedRows = tempFilterBpm
        }, 200);

        this.filterOpened = false;
        this.filterActive = true;
      }
      this.countStatus();
    },
    removeFilter() {
      this.filterOpened = false;
      this.filterActive = false;
      this.areasFilterSelected = [];
      this.statusFilterSelected = [];
      this.rexFilterSelected = []
      this.bpmFilterSelected = []

      this.mountPage()
    },
    async populateHierarchyArray() {
      function visitNodes(tree, path = []) {
        if (!tree) {
          return [];
        }

        path.push(tree.label);
        const info = {
          id: tree.id,
          label: tree.label,
          path: path.join(" -> "),
          selectable: tree.selectable,
        };

        const result = [info];

        if (tree.children && tree.children?.length > 0) {
          for (const children of tree.children) {
            result.push(...visitNodes(children, path.slice()));
          }
        }

        return result;
      }

      for (let i = 0; i < this.areaHierarchy?.length; i++) {
        this.allAreasList = this.allAreasList.concat(
          visitNodes(this.areaHierarchy[i])
        );
      }

      return true
    },
  },
};
</script>
<style>
.customDataTableCampaign th {
  display: none;
}

.customDataTableCampaign .p-datatable-tbody tr td {
  padding-top: 16px;
  padding-bottom: 16px;
}

.customDataTableCampaign .p-datatable-loading-overlay {
  display: none;
}

.customDataTableCampaign .p-datatable-row-expansion {
  background: white !important;
}

.p-datatable .p-datatable-tbody>tr {
  background-color: transparent !important;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  background-color: var(--color_gray_xlight);
  color: var(--color_gray_xdark);
  border: 1px solid var(--color_gray_light);
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 40px;
  width: fit-content;
  justify-content: center;
  align-items: center;
}

.p-chip .p-chip-text {
  margin: 0px;
}

.p-tag {
  font-weight: 800;
  font-size: 0.9rem;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: var(--color_brand_medium);
}
</style>
